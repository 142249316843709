<template>
  <div>
    <div class="py-2 text-general">
      <div class="p-2 d-flex justify-content-between">
        <label class="header-text text-main">รายละเอียดรายการขาย</label>
      </div>
      <div class="p-3 border-bottom">
        <div class="row align-items-center">
          <div class="col-6 font-weight-bold">{{ form.ticket_no }}</div>
          <div class="col-6 text-right">
            <router-link class="link-to" to="/sale-order">กลับ</router-link>
          </div>
          <div class="col-6 font-weight-bold">
            {{ form.first_name || "-" }}
            {{ form.last_name }}
          </div>
          <div class="col-6 text-right">
            {{ form.grand_total | numeral("0,0.00") }} บาท
          </div>
          <div class="col-6 font-weight-bold">
            ({{ form.telephone || "-" }})
          </div>
          <!-- <div class="col-6 text-right text-success">
            +{{ form.point | numeral("0,0") }} คะแนน
          </div> -->
          <div class="col-6 text-right text-gray-2">
            Sale ({{ form.sale_no || "-" }})
          </div>
          <div class="col-6 text-gray-2">
            {{ form.created_time | moment("DD MMM YYYY (HH:mm)") }}
          </div>
          <div class="col-6 text-right text-gray-2">
            <!-- Sale ({{ form.sale_no || '-' }}) -->
          </div>
          <div class="col-6 text-gray-2">{{ form.branch_name }}</div>
          <div class="col-6 text-right text-gray-2">
            {{ invoiceNoList.toString() }}
          </div>
        </div>
      </div>
      <div class="footer-page d-flex justify-content-between p-2">
        <b-button
          class="radius-btn btn-link mr-1"
          block
          :disabled="isLoading"
          to="/sale-order"
          >ย้อนกลับ
        </b-button>
        <b-button
          class="radius-btn bg-main ml-1"
          block
          :disabled="isLoading"
          @click="handleDelete"
          >ยกเลิกรายการขาย
        </b-button>
      </div>
      <ModalLoading ref="modalLoading" :hasClose="false" />
      <ModalAlertConfirm
        msg="คุณต้องการยกเลิกรายการนี้ ?"
        :text="modalMessage"
        colorBtnCancel="outline"
        colorBtnConfirm="secondary"
        btnCancel="ยกเลิก"
        btnConfirm="ยืนยัน"
        ref="isModalAlertConfirm"
        @confirm="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { mapGetters } from "vuex";

export default {
  name: "ReportIndex",
  components: {
    ModalLoading,
    ModalAlertConfirm,
  },
  computed: {
    ...mapGetters({ salesNo: "getSalesNo", branchId: "getBranchId" }),
    invoiceNoList() {
      return this.form.invoiceNoList.map((a) => a.invoice_no);
    },
  },
  data() {
    return {
      isLoading: false,
      modalMessage: "",
      id: this.$route.params.id,
      form: {
        ticket_no: "",
        first_name: "",
        last_name: "",
        member_id: "",
        sale_no: "",
        branch_id: 0,
        branch_name: "",
        grand_total: 0.0,
        discount: 0.0,
        invoiceNoList: [],
      },
    };
  },
  async created() {
    await this.$store.dispatch("setMainLoading", true);
    this.filter.memberId = this.$route.query.memberID || "";
   
    await this.getDetail();
    await this.$store.dispatch("setMainLoading", false);
  },
  methods: {
    async getDetail() {
      this.isBusy = true;
      await this.axios
        .get(`${this.$baseUrlSaleOrder}/saleOrder/${this.id}`)
        .then((data) => {
          if (data.result == 1) {
            this.form = data.detail;
          }
          this.isBusy = false;
        });
    },
    handleDelete() {
      this.modalMessage = this.form.ticket_no;
      this.$refs.isModalAlertConfirm.show();
    },
    async deleteItem() {
      this.$refs.isModalAlertConfirm.hide();
      await this.axios
        .delete(`${this.$baseUrlSaleOrder}/saleOrder/${this.form.id}`)
        .then(async (data) => {
          if (data.result == 1) {
            this.successAlert();
            this.$router.push({ path: `/sale-order` }).catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        })
        .catch((err) => {
          this.warningAlert(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-slide-btn {
  background-color: #e8e9ec;
  border: none;
}
.link-to {
  text-decoration: underline;
  color: #7c7a7a;
}
.text-main {
  color: var(--primary-color) !important;
}
.btn-link {
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
  margin-top: 0.5rem;
}
.footer-page {
  width: 100%;
  position: fixed;
  bottom: 60px;
  left: 0;
}
</style>
